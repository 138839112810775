<template>
  <div class="card h-100">
    <router-link tag="a" :to="{ path: '/blog-news/'+id+'/'+slug }">
        <b-img class="img" :src="img" fluid />
        <legend class="legend">{{ legend }}</legend>
    </router-link>
  </div>
</template>

<script>
export default {
    props:{
        id: {
            type: String,
        },
        slug: {
            type: String,
        },
        img: {
            type: String,
        },
        legend : {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
.card{
    display: flex;
    flex-wrap: wrap;
    width: 280px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: transparent !important;
    border: none !important;
    .img{
        background-color: #ffffff;
        width: 100% !important;
        display: flex;
        padding: 10px;
        height: 170px !important;
    }
    .legend{
        display: block;
        width: 95%;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 16px;
        margin-top: 5px;
        color: #ffffff;
        text-align: left;
        padding: 5px;
    }
    a{
        text-decoration: none !important;
    }
}
</style>